.portal-notification-sidenav {
  width: 256px;
  display: flex;
  flex-direction: column;
}

.portal-notification-sidenav__content {
  flex: 1 1 100%;

}
