  .container {
    height: 100%;
  }
  .logo {
     position: absolute;
     top: 20px;
     left: 20px;
     width: 400px;
     height: 32px;
     padding: 0px;
     text-align: left;
 }

 .logo a {
     display: block;
     position: relative;
     color: #fff;
     text-decoration: none;
     font-weight: 700;
     font-size: 20px;
     line-height: 32px;
 }

 .logo a .logo-image {
    max-height: 32px;
    max-width: 32px;
    float: left;
    margin-right: 10px;
 }