.portal-today-wrapper {
  padding: 16px;
}

.portal-today-date {
  font-weight: 300;
  padding: 16px;
  margin: 16px 0;
}

.portal-weather-temp {
  font-size: 32px;
  font-weight: 300;
  padding: 5px 16px;
}

.portal-today-card {
  margin-bottom: 16px;
  border-radius: 4px;
}
