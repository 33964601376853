html {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

html, body, #root {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  /* font-family: 'Barlow', sans-serif; */
  font-family: 'Poppins', sans-serif;
}

.portal-hide-scrollbars {
  -ms-overflow-style: none;
}

.portal-hide-scrollbars::-webkit-scrollbar {
  width: 0;
}

.portal-flex {
  flex: 1 1 auto;
}

/* Elements Pages */
.portal-pages__header {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  background: rgba(0,0,0,0.050);
}

.portal-pages__header-demo {
  max-width: 400px;
  margin-top: 0;
  margin-bottom: 150px;
  padding: 0px 48px;
  display: flex;
  flex: 1 1 auto;
  position: relative;
  justify-content: center;
}

.portal-pages__content-inner {
  margin-top: -200px;
  padding: 16px;
  box-shadow: 0px -40px 100px -50px rgba(0, 0, 0, 0.3), -20px 0px 40px -50px rgba(0, 0, 0, 0.2), 20px 0px 40px -50px rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-animation: portal-pages__content-inner .3s forwards 1 ease-in-out;
          animation: portal-pages__content-inner .3s forwards 1 ease-in-out;
}

@-webkit-keyframes portal-pages__content-inner {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes portal-pages__content-inner {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.container {
  height: 100%; }

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 400px;
  height: 32px;
  padding: 0px;
  text-align: left; }

.logo a {
  display: block;
  position: relative;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px; }

.logo a .logo-image {
  max-height: 32px;
  max-width: 32px;
  float: left;
  margin-right: 10px; }

.notification-sidenav_portal-notification-sidenav__3j_Mw {
  width: 256px;
  display: flex;
  flex-direction: column; }

.notification-sidenav_portal-notification-sidenav__content__1zCOD {
  flex: 1 1 100%; }

.today-tab_portal-today-wrapper__1uukl {
  padding: 16px; }

.today-tab_portal-today-date__182hb {
  font-weight: 300;
  padding: 16px;
  margin: 16px 0; }

.today-tab_portal-weather-temp__1Zzg4 {
  font-size: 32px;
  font-weight: 300;
  padding: 5px 16px; }

.today-tab_portal-today-card__3F1_o {
  margin-bottom: 16px;
  border-radius: 4px; }

.notifications-tab_portal-notifications-wrapper__3R8-5 {
  padding: 16px; }

.notifications-tab_portal-notifications-card__3IgYD {
  margin-bottom: 16px;
  border-radius: 4px; }

.sidenav_portal-toolbar-brand__23AVA {
  max-height: 64px;
  max-width: 100%;
  padding: 8px 0px;
  box-sizing: border-box;
  object-fit: contain; }

.sidenav_portal-toolbar-brand-title__iI-6k {
  font-weight: 600 !important; }

.sidenav_portal-toolbar-brand-text__2TQu3 {
  overflow: hidden;
  text-overflow: ellipsis; }

.sidenav_portal-sidenav-paymachine__22ZDA, .sidenav_portal-sidenav-classic__2xDMg, .sidenav_portal-sidenav-toolbar__34s2r, .sidenav_portal-sidenav-boxed__29A9_ {
  width: 286px; }

.sidenav_portal-sidenav-compact__3Ri08 {
  width: 120px; }

.sidenav_portal-sidenav-funky__1hhfs {
  width: 96px; }

.layout-paymachine_layout-paymachine-wrapper__39cR9 {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row; }

.layout-paymachine_layout-paymachine-main__Zcbnn {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.layout-paymachine_layout-paymachine-content-wrapper__FDnsn {
  flex: 1 1 100%;
  box-sizing: border-box;
  flex-direction: column-reverse;
  display: flex;
  position: relative;
  overflow: hidden; }

.layout-paymachine_layout-paymachine-content__2SHYk {
  height: 100%;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  overflow-y: auto; }

.login_panel__1AKvJ {
  -webkit-animation: login_portal-login__8ZR-p 1s forwards 1;
          animation: login_portal-login__8ZR-p 1s forwards 1;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  opacity: 0; }

.login_card__3Ce59 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px; }
  @media screen and (max-width: 568px) {
    .login_card__3Ce59 {
      padding: 12px; } }

.login_login-actions__RfzBU {
  justify-content: space-between; }

.login_login-actions__RfzBU a {
  width: 50%;
  text-transform: none; }

@media screen and (max-width: 568px) {
  .login_cardContent__12pEj {
    padding: 12px !important; } }

.login_signup-content__1-ClR {
  text-align: left;
  font-size: 14px; }
  @media screen and (max-width: 568px) {
    .login_signup-content__1-ClR {
      padding: 12px !important; } }

.login_signup-content-paragraph__3wz7- {
  color: #fff !important;
  margin-bottom: 20px !important;
  line-height: 24px !important; }
  @media screen and (max-width: 568px) {
    .login_signup-content-paragraph__3wz7- {
      margin-bottom: 0 !important;
      font-size: 0.9rem !important;
      line-height: 0.9rem !important; } }

.login_signup-logo__1KZVe {
  height: 25px;
  margin-right: 10px;
  float: left; }
  @media screen and (max-width: 568px) {
    .login_signup-logo__1KZVe {
      height: 20px; } }

.login_signup-logo-text__2QVE4 {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #fff !important;
  margin-bottom: 15px !important; }
  @media screen and (max-width: 568px) {
    .login_signup-logo-text__2QVE4 {
      font-size: 16px !important;
      margin-bottom: 8px !important; } }

@-webkit-keyframes login_portal-login__8ZR-p {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1; } }

@keyframes login_portal-login__8ZR-p {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1; } }

